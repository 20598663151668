import { Injectable } from '@angular/core';
import { 
  GetUserCommand,
  GetUserCommandInput,
  InitiateAuthCommand, 
  InitiateAuthCommandInput,
  CognitoIdentityProvider, 
  RespondToAuthChallengeCommand,
  RespondToAuthChallengeCommandInput,
  NotAuthorizedException,
  ChallengeNameType} from '@aws-sdk/client-cognito-identity-provider';
import { environment } from '@environ/environment';
import { SRPClient, calculateSignature, getNowString } from 'amazon-user-pool-srp-client'
import { TranslateService } from '@ngx-translate/core';
import { jwtDecode } from 'jwt-decode';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})

export class AuthServiceService {

  private userPoolId = environment.AWS_COGNITO_USERPOOL_ID.split('_')[1];
  private client = new CognitoIdentityProvider({region:environment.AWS_COGNITO_REGION});
  private srpClient = new SRPClient(this.userPoolId);
  constructor(private translateService:TranslateService, private router:Router, private cookierService:CookieService) { }

  async logIn(email:string,password:string){
    let errorMessage="";

    try{
      //Start auth flow with USER_SRP_AUTH
      let srpA = this.srpClient.calculateA();
      let input:InitiateAuthCommandInput= {
        AuthFlow:'USER_SRP_AUTH',
        ClientId:environment.AWS_COGNITO_APP_ID,
        AuthParameters:{
          SRP_A:srpA,
          USERNAME:email
         }
      };
  
      const initAuthcommand = new InitiateAuthCommand(input);
  
      let response = await this.client.send(initAuthcommand);
      
      //Handle next challenge (Specific case for validating password with SRPA and SRPB)
      if(response.ChallengeName === "PASSWORD_VERIFIER"){
        let salt: string = String(response.ChallengeParameters?.['SALT'] || '');
        let secretBlock: string = String(response.ChallengeParameters?.['SECRET_BLOCK'] || '');
        let srpB: string = String(response.ChallengeParameters?.['SRP_B'] || '');
        //let username: string = String(response.ChallengeParameters?.['USERNAME'] || '');
        let userdForSRP: string = String(response.ChallengeParameters?.['USER_ID_FOR_SRP'] || '');
              
        const dateNow = getNowString();
        const hkdf = this.srpClient.getPasswordAuthenticationKey(userdForSRP,password,srpB,salt);
        const signatureString = calculateSignature(hkdf,this.userPoolId,userdForSRP,secretBlock,dateNow);
        let input:RespondToAuthChallengeCommandInput = {
          ClientId:environment.AWS_COGNITO_APP_ID,
          ChallengeName:ChallengeNameType.PASSWORD_VERIFIER,
          ChallengeResponses:{
            PASSWORD_CLAIM_SIGNATURE: signatureString,
            PASSWORD_CLAIM_SECRET_BLOCK: secretBlock,
            TIMESTAMP: dateNow,
            USERNAME: userdForSRP
          }
        };
  
        const respondChalledCommand = new RespondToAuthChallengeCommand(input);
        response = await this.client.send(respondChalledCommand);

        return response;
    
        
      }else{
        //Return error if challenge is not contemplated
        this.translateService.get('others.errorMessages.unexpectedError').subscribe((translation: string) => {
          errorMessage = translation;
        });
        throw new Error(errorMessage)
      }
    }
  catch(error){
    //Error if unauthorized
    if(error instanceof NotAuthorizedException){
      this.translateService.get('others.cognitoErrorMessages.unauthorized').subscribe((translation: string) => {
        errorMessage = translation;
      });
    //Unhandled error
    }else{
      this.translateService.get('others.errorMessages.unexpectedError').subscribe((translation: string) => {
        errorMessage = translation;
      });
    }

    throw new Error(errorMessage)
  }

  }

  async forcePasswordChange(session:string,password:string,username:string){
    let errorMessage=''
    try{
      let input:RespondToAuthChallengeCommandInput = {
        ClientId:environment.AWS_COGNITO_APP_ID,
        ChallengeName:ChallengeNameType.NEW_PASSWORD_REQUIRED,
        ChallengeResponses:{
          USERNAME:username,
          NEW_PASSWORD:password
        },
        Session:session
      };


      const respondChalledCommand = new RespondToAuthChallengeCommand(input);
      let response = await this.client.send(respondChalledCommand);

      return response;
      
    }catch(error){
    //Error if unauthorized
    if(error instanceof NotAuthorizedException){
      this.translateService.get('others.cognitoErrorMessages.sessionExpired').subscribe((translation: string) => {
        errorMessage = translation;
      });
      //Unhandled error
      }else{
        this.translateService.get('others.errorMessages.unexpectedError').subscribe((translation: string) => {
          errorMessage = translation;
        });
      }

      throw new Error(errorMessage)
    }
  }

  async isUserAuthenticated(accessToken:string):Promise<boolean>{
    try{

      if(this.isTokenExpired(this.cookierService.get('access_token') || '')){
        return false;
      };

      let input:GetUserCommandInput = {AccessToken:accessToken};
      let command = new GetUserCommand(input);
      let response = await this.client.send(command);

      if(response.Username){
        return true;
      }

      return false;

    }catch(error){
      console.log('Error while checking user auth')
      return false;
    }
  }

  isTokenExpired(token:string){
    try{
      const decodedToekn = jwtDecode(token);
      const exp:any = decodedToekn.exp;
      const currentTiem = Math.floor(new Date().getTime() / 1000);
      return currentTiem > exp;
    }catch(error){
      console.log('Token not found or invalid');
      return true;
    }
  }

  getCognitoIdFromToken(token:string){
    try{
      const decodedToekn:any = jwtDecode(token);
      const username:any = decodedToekn.username;
      return username;
    }catch(error){
      return null;
    }
  }

  logOut(){
    //buggy
    this.cookierService.deleteAll('/');

    if(this.router.url === '/landing'){
      window.location.reload();
    }else{
      this.router.navigate(['/','landing']);
    }

    
  }
}
