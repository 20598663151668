import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '@environ/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IRegisterUserForm } from '@core/models/IRegisterUserForm';
import { 
  ForgotPasswordCommand, 
  ForgotPasswordCommandInput, 
  CognitoIdentityProvider, 
  ConfirmForgotPasswordCommand, 
  ConfirmForgotPasswordCommandInput,
  InvalidPasswordException,
  ExpiredCodeException,
  CodeMismatchException
} from '@aws-sdk/client-cognito-identity-provider';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private activeVersion:string =  '';
  private apiStage: string = '';
  private usersPath:string='';
  private uploadFiles:string = ''
  private fullApiUrlUsers:string='';
  private client = new CognitoIdentityProvider({region:environment.AWS_COGNITO_REGION});
  private fullApiUrlFiles:string =environment.apiUrl + '/' + this.apiStage + '/' + this.uploadFiles + '/' + this.activeVersion;

  constructor(private http:HttpClient, private translateService:TranslateService) { 
    if(environment.local){
      this.activeVersion='V1';
      this.usersPath = 'users';
      this.uploadFiles = 'files';
      this.fullApiUrlUsers = environment.apiUrl + '/' +  this.usersPath + '/' + this.activeVersion;
      this.fullApiUrlFiles = environment.apiUrl + '/' +  this.uploadFiles + '/' + this.activeVersion;

    }else{
      this.activeVersion='V1';
      this.apiStage = environment.production ? 'PROD' : 'DEV';
      this.usersPath = 'users';
      this.uploadFiles = 'files';
      this.fullApiUrlUsers = environment.apiUrl + '/' + this.apiStage + '/' + this.usersPath + '/' + this.activeVersion;
      this.fullApiUrlFiles = environment.apiUrl + '/' + this.apiStage + '/' + this.uploadFiles + '/' + this.activeVersion;

    }
  }
  //Auxyliary functions
  private getOwnUserData():Observable<any> {
    const registerUserEndpoint = 'getOwnUser';
    const headers = new HttpHeaders({ "Content-Type": "application/json" });

    return this.http.get(this.fullApiUrlUsers + '/' + registerUserEndpoint, {headers:headers});
  }

  private getForeignUserData(userId:string | number):Observable<any> {
    const registerUserEndpoint = 'getUserById';
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    const body = {cognitoUserId:userId}

    return this.http.post(this.fullApiUrlUsers + '/' + registerUserEndpoint, body ,{headers:headers});
  }

  private getOwnProfilePicture():Observable<any> {
    const registerUserEndpoint = 'downloadOwnPicture';
    const headers = new HttpHeaders();
    const data={};

    return this.http.post(this.fullApiUrlUsers + '/' + registerUserEndpoint,data, {headers:headers});
  }

  private getForeignProfilePicture(userId:string | number):Observable<any> {
    const registerUserEndpoint = 'downloadUserPicture';
    const headers = new HttpHeaders();
    const data={cognitoUserId:userId};

    return this.http.post(this.fullApiUrlUsers + '/' + registerUserEndpoint,data, {headers:headers});
  }

  updateUserDescription(description:string):Observable<any> {
    const registerUserEndpoint = 'updateOwnDescription';
    const headers = new HttpHeaders();
    const data={description:description};

    return this.http.patch(this.fullApiUrlUsers + '/' + registerUserEndpoint,data, {headers:headers});
  }

  changeUserProfilePicture(payload:FormData):Observable<any>{
    const registerUserEndpoint = 'updateProfilePicture';
    const headers = new HttpHeaders();

    return this.http.patch(this.fullApiUrlFiles + '/' + registerUserEndpoint,payload, {headers:headers});
  }

  async sendPasswordResetCode(email:string){
    let errorMessage='';
    try{
      let input:ForgotPasswordCommandInput={
        ClientId:environment.AWS_COGNITO_APP_ID,
        Username:email
      };

      let command:ForgotPasswordCommand = new ForgotPasswordCommand(input);
      let response = await this.client.send(command);
      return response;

    }catch(error){
      this.translateService.get('others.errorMessages.unexpectedError').subscribe((translation: string) => {
        errorMessage = translation;
      });
    
      throw new Error(errorMessage)
    }
  }

  async confirmPasswordResetCode(email:string,code:string,password:string){
    let errorMessage='';
    try{
      let input:ConfirmForgotPasswordCommandInput={
        ClientId:environment.AWS_COGNITO_APP_ID,
        Username:email,
        ConfirmationCode:code,
        Password:password
      };

      let command:ConfirmForgotPasswordCommand = new ConfirmForgotPasswordCommand(input);
      let response = await this.client.send(command);
      return response;

    }catch(error){
      if(error instanceof InvalidPasswordException){
        this.translateService.get('others.cognitoErrorMessages.invalidPassword').subscribe((translation: string) => {
          errorMessage = translation;
        });
      }else if(error instanceof CodeMismatchException){
        this.translateService.get('others.cognitoErrorMessages.invalidRecoveryCode').subscribe((translation: string) => {
          errorMessage = translation;
        });
      }
      else if(error instanceof ExpiredCodeException){
        this.translateService.get('others.cognitoErrorMessages.expiredCode').subscribe((translation: string) => {
          errorMessage = translation;
        });
      }else{
        this.translateService.get('others.errorMessages.unexpectedError').subscribe((translation: string) => {
          errorMessage = translation;
        });
      }

    
      throw new Error(errorMessage)
    }
  }


  //Main Services
  public getUserData(userId:string|number|null|undefined):Observable<any>{
    if(userId !== null && userId !== undefined && userId !== ''){
      return this.getForeignUserData(userId);
      
    }
    return this.getOwnUserData();
  }

  public getProfilePicture(userId:string|number|null|undefined):Observable<any>{
    if(userId !== null && userId !== undefined && userId !== ''){
      return this.getForeignProfilePicture(userId);
    }

    return this.getOwnProfilePicture();
  }

  public registerUser(userData: IRegisterUserForm):Observable<any> {
    const registerUserEndpoint = 'registerUser';
    const headers = new HttpHeaders({ "Content-Type": "application/json", "skipInterceptor": "true" });
    const body = JSON.stringify(userData); // Include user data in the request body
  
    return this.http.put(this.fullApiUrlUsers + '/' + registerUserEndpoint, body, { headers: headers });
  }

  public acceptTermsAndConditions():Observable<any> {
    const registerUserEndpoint = 'acceptTermsAndConditions';
    const headers = new HttpHeaders({ "Content-Type": "application/json"});
    const body = {};
  
    return this.http.patch(this.fullApiUrlUsers + '/' + registerUserEndpoint, body, { headers: headers });
  }

  public updateUserAsociate(userId:string|number|null|undefined):Observable<any> {
    const registerUserEndpoint = 'updateAssociateStatus';
    const headers = new HttpHeaders({ "Content-Type": "application/json"});
    const body = {cognitoUserId:userId}
  
    return this.http.patch(this.fullApiUrlUsers + '/' + registerUserEndpoint, body, { headers: headers });
  }

  public monitorUser(userId:string|number|null|undefined, keyWord:string):Observable<any> {
    const registerUserEndpoint = 'associateUser';
    const headers = new HttpHeaders({ "Content-Type": "application/json"});
    const body = {cognitoUserId:userId, keyWord:keyWord}
  
    return this.http.patch(this.fullApiUrlUsers + '/' + registerUserEndpoint, body, { headers: headers });
  }


  public stopUserMonitor(userId:string|number|null|undefined, keyWord:string):Observable<any> {
    const registerUserEndpoint = 'dissociateUser';
    const headers = new HttpHeaders({ "Content-Type": "application/json"});
    const body = {cognitoUserId:userId, keyWord:keyWord}
  
    return this.http.patch(this.fullApiUrlUsers + '/' + registerUserEndpoint, body, { headers: headers });
  }

  public updateOwnFamilyInformation(familyInfo:any):Observable<any> {
    const registerUserEndpoint = 'updateOwnFamilyInfo';
    const headers = new HttpHeaders({ "Content-Type": "application/json"});
    const body = {familyInfo:familyInfo}
  
    return this.http.patch(this.fullApiUrlUsers + '/' + registerUserEndpoint, body, { headers: headers });
  }

  public getAdditionalData(userId:string|number|null|undefined):Observable<any> {
    const registerUserEndpoint = 'getAdditionalData';
    const headers = new HttpHeaders({ "Content-Type": "application/json"});
    const body = {cognitoUserId:userId}
  
    return this.http.post(this.fullApiUrlUsers + '/' + registerUserEndpoint, body, { headers: headers });
  }

  public updateAdditionalData(userId:string|number|null|undefined,additionalDataId:string|number,keyValue:string):Observable<any> {
    const registerUserEndpoint = 'updateAdditionalData';
    const headers = new HttpHeaders({ "Content-Type": "application/json"});
    const body = {cognitoUserId:userId,additionalDataId:additionalDataId,keyValue:keyValue}
  
    return this.http.post(this.fullApiUrlUsers + '/' + registerUserEndpoint, body, { headers: headers });
  }

  public deleteAdditionalData(userId:string|number|null|undefined,additionalDataId:string|number):Observable<any> {
    const registerUserEndpoint = 'deleteAdditionalData';
    const headers = new HttpHeaders({ "Content-Type": "application/json"});
    const body = {cognitoUserId:userId,additionalDataId:additionalDataId}
  
    return this.http.post(this.fullApiUrlUsers + '/' + registerUserEndpoint, body, { headers: headers });
  }

  public createAdditionalData(userId:string|number|null|undefined,keyName:string, keyValue:string):Observable<any> {
    const registerUserEndpoint = 'createAdditionalData';
    const headers = new HttpHeaders({ "Content-Type": "application/json"});
    const body = {cognitoUserId:userId,keyName:keyName,keyValue:keyValue}
  
    return this.http.post(this.fullApiUrlUsers + '/' + registerUserEndpoint, body, { headers: headers });
  }

  public uploadCv(formData:FormData):Observable<any> {
    const registerUserEndpoint = 'loadPrivateUserCv';
    const headers = new HttpHeaders();

    return this.http.post(this.fullApiUrlFiles + '/' + registerUserEndpoint, formData, { headers: headers });
  }

  public downloadCv(userId:string|number|null|undefined):Observable<any> {
    const registerUserEndpoint = 'downloadPrivateUserCv';
    const headers = new HttpHeaders({ "Content-Type": "application/json"});
    const body = {cognitoUserId:userId}
  
    return this.http.post(this.fullApiUrlFiles + '/' + registerUserEndpoint, body, { headers: headers });
  }

  public updateBasicUserData(cellphone:string,cellphoneCode:string,country:string,city:string,department:string,genero:string,document:string,documentType:string,
    firstName:string, secondName:string, firstSurname:string, secondSurname:string, address:string,blodType:string,countryBorn:string, cityBorn:string, addressBorn:string
  ):Observable<any> {
    const registerUserEndpoint = 'updateOwnGeneralInfo';
    const headers = new HttpHeaders({ "Content-Type": "application/json"});
    const body = {cellphone,cellphoneCode,country,city,department,gender:genero,idDocNumber:document,idDocType:documentType, firstName:firstName, secondName:secondName, firstSurname:firstSurname, secondSurname:secondSurname,address:address, blodType:blodType,countryBorn:countryBorn,cityBorn:cityBorn,addressBorn:addressBorn}
    console.log(body)
    return this.http.patch(this.fullApiUrlUsers + '/' + registerUserEndpoint, body, { headers: headers });
  }

  public updateOwnContactInfo(cellphone:string,cellphoneCode:string,country:string,city:string,department:string,genero:string,document:string,documentType:string):Observable<any> {
    const registerUserEndpoint = 'updateOwnGeneralInfo';
    const headers = new HttpHeaders({ "Content-Type": "application/json"});
    const body = {cellphone,cellphoneCode,country,city,department,genero,document,documentType}
  
    return this.http.patch(this.fullApiUrlUsers + '/' + registerUserEndpoint, body, { headers: headers });
  }

  public sendResetPasswordEmail(userId:string|number|null|undefined):Observable<any> {
    const endpoint = 'resetUserPassword';
    const headers = new HttpHeaders({ "Content-Type": "application/json"});
    const body = {cognitoUserId:userId}
  
    return this.http.put(this.fullApiUrlUsers + '/' + endpoint, body, { headers: headers });
  }

  public updateStatusUserData(userId:string|number|null|undefined,hired:any=null,position:any=null,area:any=null){
    const registerUserEndpoint = 'updateUserStatusData';
    const headers = new HttpHeaders({ "Content-Type": "application/json"});
    const body = {cognitoUserId:userId,hired,position,area}
  
    return this.http.patch(this.fullApiUrlUsers + '/' + registerUserEndpoint, body, { headers: headers });
  }

  public acceptHabeasData():Observable<any> {
    const registerUserEndpoint = 'acceptHabeasData';
    const headers = new HttpHeaders({ "Content-Type": "application/json"});
    const body = {};
  
    return this.http.patch(this.fullApiUrlUsers + '/' + registerUserEndpoint, body, { headers: headers });
  }

  public toggleUserEditData(userId:string|number|null|undefined):Observable<any> {
    const registerUserEndpoint = 'toggleDataEdition';
    const headers = new HttpHeaders({ "Content-Type": "application/json"});
    const body = {cognitoUserId:userId}
  
    return this.http.patch(this.fullApiUrlUsers + '/' + registerUserEndpoint, body, { headers: headers });
  }

  public toggleUserCodeEdition(userId:string|number|null|undefined):Observable<any> {
    const registerUserEndpoint = 'toggleManualCodeEdition';
    const headers = new HttpHeaders({ "Content-Type": "application/json"});
    const body = {cognitoUserId:userId}
  
    return this.http.patch(this.fullApiUrlUsers + '/' + registerUserEndpoint, body, { headers: headers });
  }

  resendTempCredentials(email:string|number|null|undefined):Observable<any> {
    const registerUserEndpoint = 'resendTempCredentials';
    const headers = new HttpHeaders({ "Content-Type": "application/json"});
    const body = {userEmail:email}
  
    return this.http.patch(this.fullApiUrlUsers + '/' + registerUserEndpoint, body, { headers: headers });
  }



  public updateManualCode(manualCode:string):Observable<any> {
    const registerUserEndpoint = 'updateOwnManualCode';
    const headers = new HttpHeaders({ "Content-Type": "application/json"});
    const body = {code:manualCode}

    return this.http.patch(this.fullApiUrlUsers + '/' + registerUserEndpoint, body, { headers: headers });
  }

  public updateManualCodeForeign(manualCode:string,userId:string|number|null|undefined):Observable<any> {
    const registerUserEndpoint = 'updateForeignManualCode';
    const headers = new HttpHeaders({ "Content-Type": "application/json"});
    const body = {code:manualCode,cognitoUserId:userId}

    return this.http.patch(this.fullApiUrlUsers + '/' + registerUserEndpoint, body, { headers: headers });
  }

}
