import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

interface AlertNotification {
  message: string;
  severity: 'ERROR' | 'SUCCESS' | 'WARNNING';
  detail:{}|null|undefined
}



@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor() { }

  private alertSubject = new Subject<AlertNotification>();
  public alert$ = this.alertSubject.asObservable();

  private loaderSubject = new Subject<any>();
  public loader$ = this.loaderSubject.asObservable();

  emitAlert(message: string, severity: 'ERROR' | 'SUCCESS' | 'WARNNING', detail?:{}|null|undefined) {
    this.alertSubject.next({ message, severity, detail});
  }

  emitLoader(){
    this.loaderSubject.next(true)
  }
  
}

